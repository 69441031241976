.start {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #EB4D3F;
}
.stop {
    height: 24px;
    width: 24px;
    border-radius: 4px;
    background: #EB4D3F;
}
.transition {
    transition: all 215ms ease;
}
.record-button {
    height: 60px;
    width: 60px;
    border-radius: 32px;
    border: 1px solid #C4C4C4;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.button {
    border: 1px solid black;
    padding: 5px;
    cursor: pointer;
}
.center {
    align-self: center;
}

.flex {
    display: flex;
}

.hidden {
    visibility: hidden;
}

img {
    cursor: pointer;
}

.play {
  width: 20px; 
  height: 20px; 
}

.step {
    width: 50%;
    border: 2px solid #C4C4C4;
    background-color: #ffffff;
    padding: 25px;
    border-radius: 10px;
    color: #414B58;
    min-height: 300px;
    box-shadow: 1px 3px #E5E5E5;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}
/*
#waveform {
    width: 500px;
    height: 50px;
}*/

.unplayed {
    width: 100%;
    height: 3px;
    background-color: #DDE1E6;
    position: absolute;
    margin: auto;
    top: 0; left: 0; bottom: 0; right: 0;

}

.current {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    position: absolute;
    background-color: #8694A5;
    left: 200px;
}

.player {
    width: 350px;
    position: relative;
    height:8px;
}

.played {
    background: #8694A5;
    position: absolute;
    /*width: 200px;*/
    height: 3px;
    margin-top: auto;
    margin-bottom: auto;
    top: 0; left: 0; bottom: 0; right: 0;
}

.play {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid #C4C4C4;
    cursor: pointer;
    display: flex;
    align-items: center;
  justify-content: center;
}

.secondary-player-button {
    height: 54px;
    width: 54px;
    border-radius: 50%;
    border: 1px solid #C4C4C4;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.player-controls {
    padding-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.duration {
    padding-bottom: 10px;
}

.button-label {
    font-size: 10px;
    margin-top: auto;
    display: flex;
}

.button-and-label {
    padding-right: 20px;
        display: flex;
    flex-direction: column;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80px;
    flex:1;
}

.small-record {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: #EB4D3F;
}

.subtext {
    font-size: 15px;
    margin-bottom: 25px;
}

h3 {
    margin-bottom: 10px;
}

::-moz-selection { background:transparent; }
::selection { background:transparent; }

.share {
    size: 50%;
    margin-top: 20px;
}